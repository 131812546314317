
<script>
import { defineComponent, computed, inject, ref, onMounted, onBeforeUnmount, watch } from 'vue'
import { animate, actionSetOA2T, actionOA2T } from '@kit/utils/Animate'
import Modal from '@kit/components/Modal.vue'
import IconButton from '@kit/components/IconButton.vue'
import { signupButton, blueButton, blueModal, socialMediaButton } from '@project/themes'
import { useReCaptcha } from 'vue-recaptcha-v3'
import { signup } from '@project/api/newsletter/v1'
import { inBrowser } from '@kit/utils/EnvironmentHelper'
import { sleep } from '@kit/utils/Sleep'
import { getCurrentYear } from '@kit/utils/Formats'


export default defineComponent({
  name: "Footer",
  setup() {

    const emailBackground = ref(null)
    const emailLabel = ref(null)
    const emailInput = ref(null)
    const emailSignup = ref(null)
    const modalOpen = ref(false)
    const loading = ref(false)
    const serverResult = ref(null)
    const emailAddress = ref("")
    const submitButtonPressed = ref(false)
    const modalStartsFresh = ref(true)
    let lastInputValue = ""

    const themer = inject("themer")

    const blueModalTheme = themer(blueModal)
    const blueButtonTheme = themer(blueButton)
    const signupButtonTheme = themer(signupButton)
    const socialMediaButtonTheme = themer(socialMediaButton)

    const { executeRecaptcha, recaptchaLoaded } = inBrowser ? useReCaptcha() : {}

    const recaptcha = async () => {
      if(inBrowser) {
        //(optional) Wait until recaptcha has been loaded.
        await recaptchaLoaded()

        // Execute reCAPTCHA with action "login".
        const token = await executeRecaptcha('newsletter_signup')
        //Do stuff with the received token.
        return token
      }
    }

    let emailInputNode = null
    let emailLabelNode = null
    let emailBackgroundNode = null
    let emailSignupNode = null

    const hydrate = inject('hydrate')

    const { prefetch } = hydrate({
      who: "Footer",
      prefetch:["common"]
    })
    const locations = computed(() => {
      return prefetch.value ? prefetch.value.common.locations : null
    })

    const focusActions = actionSetOA2T([
      {
        name:"in",
        async action() {
          emailLabelNode.innerHTML = "&nbsp;"
          animate({
            targets: emailBackgroundNode,
            background: "#d9e7fa",
            duration: 1000
          })
          animate({
            targets: emailSignupNode,
            right:"0px",
            duration:200,
            easing:"easeOutQuad"
          })
        }
      },
      {
        name:"out",
        async action() {
          emailLabelNode.innerHTML = "Email"
          animate({
            targets: emailBackgroundNode,
            background: "#FFFFFF",
            duration: 1000
          })
          animate({
            targets: emailSignupNode,
            right:"110px",
            duration:200,
            easing:"easeOutQuad"
          })
        }
      }
    ])


    const emailInputFocused = (_e) => {
      actionOA2T(focusActions, "in")
    }

    const emailAddressValid = computed(() => { 
      let val = emailAddress.value
      if(/^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/.test(val)) {
        return true
      }
      return false
    })

    const emailInputClasses = computed(() => {
      if(modalStartsFresh.value && !submitButtonPressed.value) {
        return "sb modal-address"
      } else 
      if(!emailAddressValid.value) {
        return "sb modal-address error"
      } else {
        return "sb modal-address"
      }
    })

    const emailErrorMessageVisible = computed(() => {

      if(modalStartsFresh.value && submitButtonPressed.value) {
        if(!emailAddressValid.value) {
          return true
        }
      } else 
      if(!modalStartsFresh.value) {
        if(!emailAddressValid.value) {
          return true
        }
      }

      return false
    })

    const emailInstructions = computed(() => {
      return modalStartsFresh.value ? 'Please enter your email below.' : 'Please confirm that the email below is correct:'
    })

    const emailInputBlurred = async(_e) => {}

    const emailInputChanged = async(e) => {
      await sleep(100)
      const val = e.target.value
      emailAddress.value = val

      //If the modal window is open, then don't do this.
      if(!modalOpen.value) {
        if(val.trim() == "") {
          actionOA2T(focusActions, "out")
        } else 
        if(val.trim() != "" && lastInputValue == "") {
          actionOA2T(focusActions, "in")
        }
      }
      lastInputValue = val
    }

    const openSignupConfirmation = (e) => {
      serverResult.value = null
      modalOpen.value = true
    }

    const success = computed(() => {
      return serverResult.value && serverResult.value.success
    })

    const failure = computed(() => {
      return serverResult.value && !serverResult.value.success
    })

    const displayFailMsg = computed(() => {
      if(failure.value) {
        if(serverResult.value.message) {
          return serverResult.value.message
        } else {
          return "Please try again later."
        }
      } 

      return null
    })

    const submitEmailToNewsletterEndpoint = async() => {

      submitButtonPressed.value = true
      
      if(!emailAddressValid.value) {
        return
      }

      loading.value = true
      const token = await recaptcha()
      const email = emailAddress.value
      const result = await signup({ 
        email,
        recaptchaToken:token,
        profile:"newsletter"
      })
      serverResult.value = result

      loading.value = false
    }

    

    onMounted(() => {
      emailInputNode =  emailInput.value
      emailLabelNode =  emailLabel.value
      emailBackgroundNode = emailBackground.value
      emailSignupNode = emailSignup.value

      emailInputNode.addEventListener("focus", emailInputFocused)
      emailInputNode.addEventListener("blur", emailInputBlurred)
      emailInputNode.addEventListener("input", emailInputChanged)
    })
    onBeforeUnmount(() => {
      emailInputNode.removeEventListener("focus", emailInputFocused)
      emailInputNode.removeEventListener("blur", emailInputBlurred)
      emailInputNode.removeEventListener("input", emailInputChanged)
    })
    
    watch(modalOpen, (newVal, oldVal) => {

      //If it's closing, then reset these and make sure 
      //the widget on the page resets
      if(!newVal && oldVal) {
        emailInput.value.value = ""
        submitButtonPressed.value = false
        modalStartsFresh.value = false
        emailInput.value.dispatchEvent(new Event("input"))
      } 
      
      //Else, if it's opening, then we're going to see if the
      //modal form is starting from a fresh value or not.
      else 
      if(newVal && !oldVal) {
        if(emailAddress.value == "") {
          modalStartsFresh.value = true
        } else {
          modalStartsFresh.value = false
        }
      }
    })


    return { 
      loading,
      success,
      failure,
      serverResult,
      displayFailMsg,
      locations, 
      emailBackground, 
      emailLabel, 
      emailInput, 
      emailSignup,
      emailAddress,
      emailAddressValid,
      emailInputClasses,
      emailInputChanged,
      openSignupConfirmation,
      emailInputFocused, 
      emailInstructions,
      emailErrorMessageVisible,
      modalOpen, 
      modalStartsFresh,
      signupButtonTheme, 
      blueButtonTheme,
      blueModalTheme, 
      socialMediaButtonTheme,
      submitButtonPressed,
      submitEmailToNewsletterEndpoint,
      getCurrentYear
    }
  },
  components: {
    Modal,
    IconButton
  }
});
</script>


<style scoped>

.width300px {
  width:300px !important;
}

.footer {
  margin-top:20px; 
  margin-bottom:50px;
  padding-right:100px;
  padding-left:100px;
}

.blue-logo-background-reverse {
  background: url('@images/STHS-WeCare-Blue-background_reverse.png') no-repeat;
  background-size: cover;
}

.newsletter-and-social.sb.sb-g30 {
  padding:0px;
}

.email-container { 
  padding:10px; 
  color:black;
}

input.email-list {
  position:absolute; 
  top:0px; 
  bottom:0px; 
  left:10px; 
  right:34px; 
  background:none;
}

input.email-list:focus { 
  border: none; 
  outline: none;
} 

input.modal-address:not(.error) {
  border: 1px solid #49ADAB; 
  outline: none;
  padding:15px;
  background-color: #FFFFFF;
  border-radius:10px;
  transition: background-color 0.3s;
}

input.modal-address.error {
  border: 1px solid #fc2403;
  outline: none;
  padding:15px;
  background-color: #f5b5b5;
  border-radius:10px;
}

input.modal-address:not(.error):focus {
  background-color:#c2eaed;
}

span.sb.error-msg {
  color:#fc2403;
  font-weight: bold;
  margin-top:5px !important;
}

span.sb.empty-msg {
  font-weight: bold;
  margin-top:5px !important; 
}

.red {
 color:#fc2403;
}

.email-background {
  position:absolute; 
  border-radius:20px; 
  padding:10px; 
  background:#FFFFFF; 
  top:0px; left:0px; 
  bottom:0px; 
  right:0px;
  transition: color 0.3s ease;
}

@media (max-width : 960px) {

  .blue-logo-background-reverse {
    background: url('@images/STHS-WeCare-Blue-background_reverse-mobile.png') no-repeat;
    background-size: cover;
  }

  .width300px {
    width:100% !important;
  }

  .newsletter-and-social.sb.sb-g30 {
    padding:0px 80px 0px 80px;
  }

}

</style>

<style>
  .sths-modal-window {
    background:#fff;
    border-radius:10px;
    box-shadow: 0px 0px 10px #888888;
  }
  .sths-modal-background {
    background: rgba(255,255,255,0.6);

  }
</style>

<template>

  <footer class="sb sb-v blue-logo-background-reverse white sb-g30">

    <div class="main sb sb-h sb-greedy-1 sb-ltm-h2v">

      <div class="logo-and-button sb sb-v sb-alt2 sb-align-ch">
        <div class="sb" style="height:100px;">
          <img src="@images/STHS-WeCare-logo_Tagline-transparent-white.png" alt="WeCare logo with white text" style="width:auto; height:100%; object-fit:contain;">
        </div>
        <div class="sb sb-h" style="border-top:1px solid #849CB3; border-bottom:1px solid #849CB3; margin-top:30px; padding-bottom:30px; padding-top:30px; margin-bottom:30px;">
          <a href="https://portal.we-care.org/general/" class="sb sb-text ui-button orange-bg">Make an appointment<i class="fa fa-long-arrow-right icon-md icon-end"></i></a>
        </div>
      </div>

      <div class="info-columns sb sb-greedy sb-gr sb-h sb-content-space-around">
        <!-- width:auto there's a problem in the superbox classes which I've documented. -->
        <ul v-if="locations" class="locations sb sb-v sb-gr sb-g10" style="width:auto;">
          <li class="sb teal" style="padding-bottom:20px;"><b>Locations:</b></li>

          <template v-if="locations" v-for="location in locations">
            <li class="sb sb-h sb-alt2 sb-align-cv">
              <div class="sb" style="height:1.2em;"><img src="@images/STHS-WeCare-footer-icons_map-drop.png" alt="Map geocode drop icon" style="height:100%; width:auto; object-fit:contain;"></div>
              <router-link class="sb" style="margin-left:5px; color:white;" :to="`/locations/${location.post_name}`">{{ location.nav_title }}</router-link>
            </li>
            <li class="sb sb-h sb-alt2 sb-align-cv" style="margin-top:5px;">
              <div class="sb" style="height:1.2em;"><img src="@images/STHS-WeCare-footer-icons_phone.png" alt="Phone icon" style="height:100%; width:auto; object-fit:contain;"></div><span class="sb" style="margin-left:5px;">{{location.phone_number}}</span>
            </li>
          </template>

        </ul>
        <ul class="learn-more sb sb-v sb-gr sb-g10" style="width:auto;">
          <li class="sb teal" style="padding-bottom:20px;"><b>Learn More:</b></li>
          <li class="sb sb-text"><router-link class="white" to="/services/primary-care">Primary Care</router-link></li>
          <li class="sb sb-text"><router-link class="white" to="/services/dental">Dental</router-link></li>
          <li class="sb sb-text"><router-link class="white" to="/services/behavioral-health">Behavioral Health</router-link></li>
          <li class="sb sb-text"><router-link class="white" to="/about-us">About</router-link></li>
          <li class="sb sb-text"><router-link class="white" to="/news">News</router-link></li>
          <li class="sb sb-text"><router-link class="white" to="/job-openings">Careers</router-link></li>
        </ul>
      </div>
      <form class="newsletter sb sb-explicit sb-v sb-gr sb-g20 width300px" style="padding:0px; padding-top:10px;">

        <div class="sb teal"><b>Sign up for our newsletter:</b></div>
        <div ref="emailContainer" style="padding-right:100px;" class="email-container sb sb-h sb-greedy-1 sb-alt2 sb-align-cv">
          
          <div class="sb-abox" ref="emailSignup" style="left:auto; right:110px; z-index:0;">
            <IconButton 
              text="<b>Sign up!</b>" 
              width="110px"
              :theme="signupButtonTheme"
              @buttonClick="openSignupConfirmation"
              :active="true"
              :loading="false"
            />
          </div>

          <div ref="emailBackground" class="email-background" style="right:80px;"></div>

          <span ref="emailLabel" class="sb sb-greedy" style="margin-left:0px;">Email</span>
          <input ref="emailInput" type="text" class="email-list" style="right:130px;">
          <div class="sb sb-explicit" style="height:1.2em;">
            <img style="width:auto; height:100%; object-fit:contain" alt="Email icon" src="@images/STHS-WeCare-footer-icons_email.png">
          </div>
        </div>

        
        <div class="sb" style="border-bottom:1px solid #849CB3;"></div>
        <div class="sb sb-h sb-content-space-around">

          <IconButton 
            href="https://www.facebook.com/STHSClinic"
            target="_blank"
            :theme="socialMediaButtonTheme"
            icon="facebook"/>

          <IconButton 
            href="https://twitter.com"
            target="_blank"
            :theme="socialMediaButtonTheme"
            icon="twitter"/>

        </div>
        <div class="sb" style="border-bottom:1px solid #849CB3;"></div>
        

      </form>
      

    </div>
    <div class="links-and-copyright sb sb-h sb-content-space-between sb-ltm-h2v sb-ltm-alt2 sb-ltm-align-ch">

      <div class="sb sb-text">
        © {{getCurrentYear()}} WeCare. All rights reserved. &nbsp;&nbsp; <a class="white" href="/privacy">Privacy Policy</a> &nbsp;&nbsp; <a class="white" href="/sitemap.xml">Sitemap</a> &nbsp;&nbsp; <a class="white" href="https://otis.osmanager4.com/sthsclinic">Employees</a>
      </div>
      <div class="sb sb-text" style="opacity:0.5;">Design by Bottomline Marketing</div>

    </div>

  </footer>
 
  <Modal :theme="blueModalTheme" :vw="50" :vh="50" :mvw="85" :mvh="50" v-model="modalOpen">
    <template v-slot:headline>
      <h3>Join Our Email List!</h3>
    </template>
    <template v-slot:body>  

      <template v-if="success">

        <div class="sb sb-v sb-greedy">

          <div class="sb sb-v sb-greedy-1 sb-content-strict-height">
            <h4 class="sb">Success!</h4>
            <div class="sb">We added the following email to our newsletter list:</div>
            <div class="sb sb-v sb-greedy sb-align-cv">
              <h4 class="sb" style="color:#49ADAB">{{emailAddress}}</h4>
            </div>
            <div class="sb">We're looking forward to keeping you up-to-date on all the developments at WeCare!</div>
          </div>

        </div>

      </template>
      <template v-else-if="failure">

        <div class="sb sb-v sb-greedy">

          <div class="sb sb-v sb-greedy-1 sb-content-strict-height">
            <h4 class="sb red">Sorry, there was a problem!</h4>
            <div class="sb">We weren't able to add the following email to our newsletter list:</div>
            <div class="sb sb-v sb-greedy sb-align-cv">
              <h4 class="sb">{{emailAddress}}</h4>
            </div>
            <div class="sb sb-text"><b>{{ displayFailMsg }}</b></div>
          </div>

        </div>

      </template>
      <template v-else> 

        <div class="sb sb-v sb-greedy">

          <div class="sb sb-v sb-greedy-1 sb-content-strict-height">
            <h4 class="sb">{{emailInstructions}}</h4>
            <div class="sb sb-v sb-greedy sb-align-cv">
              <input :class="emailInputClasses" @input="emailInputChanged" placeholder="Email" :value="emailAddress"/>
              <span class="sb error-msg" v-if="emailErrorMessageVisible">Invalid Email</span>
            </div>
            <IconButton  
              style="flex-shrink:0;"
              text="Sign Up!"
              icon="rounded-check_30" 
              :theme="blueButtonTheme" 
              :active="(modalStartsFresh && !submitButtonPressed) || emailAddressValid"
              :loading="loading"
              @buttonClick="submitEmailToNewsletterEndpoint"/>
          </div>

        </div>


      </template>
        
    </template>

  </Modal>

</template>